<template>
  <v-row justify="center">
    <v-dialog v-model="showModal" :persistent="persistent" :max-width="width">
      <v-card>
        <v-card-title class="headline text-center">
          <h3 class="mt-2 mb-0">{{title}}</h3>
        </v-card-title>
         <v-divider></v-divider>
        <v-container>
          <v-card-text>
          <slot></slot>
        </v-card-text>

        <v-card-actions class="d-flex justify-content-end">
          <v-spacer></v-spacer>
<slot name="footer"></slot>

        </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 300
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    closeModal () {
      this.showModal = false
    },
    openModal () {
      this.showModal = true
    },
    toggleModal () {
      this.showModal = !this.showModal
    }
  }
}
</script>
