<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>Job Titles</h1>
                </div>
                <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
                </div>
              </div>
              <v-text-field v-model="searchPersonnel"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details></v-text-field>
              <v-data-table loading-text="Loading job-title... Please wait"
                            :loading="!jobTitles.length"
                            :headers='headers'
                            :items="jobTitles"
                            :search="searchPersonnel">
                <template v-slot:item.dateAdded="{ item }">
                  <p style="width:100px"> {{item.dateAdded | formatDate}}</p>
                </template>
                <template v-slot:item.lastModifiedDate="{ item }">
                  <p style="width:80px" v-if="item.lastModifiedDate">{{item.lastModifiedDate | formatDate}}</p>
                </template>
                <template v-slot:item.salaryAmount="{ item }">
                  {{formatMoney(item.salaryAmount)}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <Button :btnType="'Submit'" :label="'Edit'" @onClick="edit(item)" class="mr-2" />
                    <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" />
                  </div>
                </template>

              </v-data-table>

              <!-- Dialog for edit  -->
              <Dialog ref="editModal" :title="'Edit'" :width="600">
                <v-row>
                  <v-col>
                    <TextField v-model="formData.name" :label="'Name'" />
                  </v-col>
                  <v-col cols="12">
                    <AmountInput v-model="formData.SalaryAmount" :label="'Salary Amount'" />
                  </v-col>
                  <v-col>
                    <!-- <SelectField
          :items="unionMembers"
          v-model="unionMember"
          :label="'Union Member'"
          :itemText="'name'"
          :itemValue ="'id'"
        /> -->

                    <v-select :items="unionMembers"
                              v-model="unionMember"
                              label="Union Member"
                              outlined
                              dense
                              item-text="name"
                              item-value="id"
                              return-object></v-select>
                  </v-col>
                </v-row>

                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Update'" @onClick="update" class="mr-2" />
                  <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="cancel('editModal')" />
                </template>
              </Dialog>

              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteJobtitle" class="mr-2" />
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>

              <!-- add  modal  -->
              <Dialog ref="addModal" :title="'Add JobTitle'" :width="600">
                <v-row>
                  <v-col cols="12">
                    <v-form ref="jobTitleForm" v-model="valid">
                      <v-col cols="12">
                        <TextField v-model="formData.name" :label="'Name'" />
                      </v-col>
                      <v-col cols="12">
                        <AmountInput v-model="formData.SalaryAmount" :label="'Salary Amount'" />
                        <SelectField :items="unionMembers"
                                     v-model="formData.unionMember"
                                     :label="'Union Member'"
                                     :itemText="'name'"
                                     :itemValue="'id'" />
                      </v-col>
                    </v-form>
                  </v-col>
                </v-row>
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Submit'" :disabled="!valid" :isLoading="isSaving" @onClick="dialog = true" class="mr-2" />
                  <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="closeAddModal" />
                </template>
              </Dialog>

              <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :isLoading="isSaving" :btnTitle="'Yes'" @close="dialog = false" @btnAction="createJobtitle" />
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import AmountInput from '@/components/ui/form/AmountInputField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { unionMemberService } from '@/services'
import Dialog from '@/components/ui/Dialog.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import { UPDATE_JOBTITLE, FETCH_JOBTITLE, DELETE_JOBTITLE, CREATE_JOBTITLE } from '@/store/action-type'
export default {
  components: {
    TextField,
    Button,
    Dialog,
    AmountInput,
    SelectField,
    ConfirmationDialog
  },
  data () {
    return {
      dialog: false,
      search: '',
      formData: {
        name: '',
        id: '',
        SalaryAmount: 0,
        unionMember: ''
      },
      unionMember: '',
      valid: false,
      searchPersonnel: '',
      isSaving: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      unionMembers: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Added By',
          value: 'addedBy'
        },
        {
          text: 'Union Member',
          value: 'unionMembership.name'
        },
        {
          text: 'Salary Amount',
          value: 'salaryAmount'
        },
        {
          text: 'Date Added',
          value: 'dateAdded'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy'
        },
        {
          text: 'Modified Date',
          value: 'lastModifiedDate'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    jobTitles () {
      return this.$store.getters.jobTitles
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    edit (item) {
      console.log(item)
      this.$refs.editModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
      this.formData.SalaryAmount = item.salaryAmount
      this.unionMember = item.unionMembership
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    formatMoney (amount, decimalCount = 2, decimal = '.', thousands = ',') {
      try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
        let j = (i.length > 3) ? i.length % 3 : 0

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
      } catch (e) {
        console.log(e)
      }
    },
    deleteJobtitle () {
      this.$store.dispatch(DELETE_JOBTITLE, this.formData).then((result) => {
        this.showAlertMessage('Job title deleted successfully', 'success')
      }).catch(() => {
        this.showAlertMessage('Job title delete failed', 'error')
      }).finally(() => {
        this.$refs.deleteModal.closeModal()
      })
    },
    update () {
      this.cancel('editModal')
      this.$store.dispatch(UPDATE_JOBTITLE, { name: this.formData.name, Action: 'new', salaryAmount: this.formData.SalaryAmount, UnionMembershipId: this.unionMember.id, id: this.formData.id }).then((result) => {
        this.showAlertMessage('Job title Updated successfully await approval', 'success')
        this.resetForm()
      }).catch(error => {
        console.log(error.message)

        this.showAlertMessage(error.message, 'error')
      })
    },

    loadUnionMembers () {
      unionMemberService.getAllUnionMembers().then(result => {
        this.unionMembers = result.data
      }).catch(error => {
        console.log(error)
      })
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
      this.SalaryAmount = 0
    },
    Add () {
      this.$refs.addModal.openModal()
    },
    closeAddModal () {
      this.formData.name = ''
      this.formData.SalaryAmount = ''
      this.$refs.addModal.closeModal()
    },
    createJobtitle () {
      this.isSaving = true
      this.$store
        .dispatch(CREATE_JOBTITLE, { name: this.formData.name, Action: 'new', SalaryAmount: this.formData.SalaryAmount, Status: 0, unionmembershipid: this.formData.unionMember })
        .then(() => {
          this.showAlertMessage('Operation successful', 'success')
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Operation failed', 'error')
        })
        .finally(() => {
          this.isSaving = false
          this.$refs.jobTitleForm.reset()
          this.closeAddModal()
          this.dialog = false
        })
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.toggleModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    }

  },
  mounted () {
    this.loadUnionMembers()
    this.$store
      .dispatch(FETCH_JOBTITLE)
      .then(() => {})
      .catch((error) => {
        if (error) {
          this.showAlertMessage('There are no items to display', 'warning')
        }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
