<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title class="headline text-center"><h3>{{title}}</h3></v-card-title>
      <v-divider class="mx-4 mb-5"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Button
          :label="btnTitle"
          :btnType="'Submit'"
          @onClick="btnAction"
          :isLoading="loading"
          class="mr-4"
        />
        <v-btn color="green darken-1" text @click.stop="show=false">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from './form/Button.vue'
export default {
  components: {
    Button
  },
  props: {
    title: {
      type: String
    },
    btnTitle: {
      type: String
    },
    dialog: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    }
  },
  computed: {
    show: {
      get () {
        return this.dialog
      },
      set (dialog) {
        if (!dialog) {
          this.$emit('close')
        }
      }
    },
    loading: {
      get () {
        return this.isLoading
      },
      set (isLoading) {
        if (!isLoading) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    btnAction () {
      this.$emit('btnAction')
    }
  }
}
</script>
