<template>
  <v-select
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    dense
    outlined
    :return-object="returnObject"
    :label="label"
    :rules="validate ? [v => !!v || 'Item is required'] : []"
    @input="$emit('input', $event)"
    @change="onChange"
  ></v-select>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      default: ''
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: ''
    },
    validate: {
      default: true,
      type: Boolean
    }
  },
  data: () => ({}),
  methods: {
    onChange (event) {
      this.$emit('onChange', event)
    }
  }
}
</script>
<style scoped>
</style>
